export default function dropout_ph_ToString(dropout_ph_ein) {
    switch (dropout_ph_ein){
        case 0:
          return "Kein Dropput"
        case 1:
          return "Austritt innerhalb von 24 h nach Eintritts-HoNOS-Messung"
        case 2: 
          return "Anderer Dropoutgrund"
        case 9:
          return "Unbekannt"
        default:
          throw new Error("Unbekannt dropout_ph_ein Wert: " + dropout_ph_ein)
    }
}