export default function ZivilstandToString(zivilstand) {
    switch (zivilstand){
        case 1:
          return "ledig"
        case 2:
          return "verheiratet, zusammenlebend"
        case 3: 
          return "verheiratet, getrennt lebend"
        case 4:
          return "verwitwet"
        case 5:
          return "geschieden"
        case 9:
          return "unbekannt"
        default:
          throw new Error("Unbekannt zivilstand Wert: " + zivilstand)
     }
}