export default function einw_instToString(einw_inst) {
    switch (einw_inst){
        case 1:
          return "selbst, Angehörige"
        case 2:
          return "Rettungsdienst (Ambulanz, Polizei)"
        case 3: 
          return "Arzt"
        case 4:
          return "nichtmedizinischer Therapeut"
        case 5:
          return "sozialmedizinischer Dienst"
        case 6:
          return "Justizbehörden"
        case 8:
          return "andere"
        case 9:
          return "unbekannt"
        default:
          throw new Error("unbekannt einw_inst Wert: " + einw_inst)
     }
}