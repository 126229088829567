export default function ort_v_einToString(ort_v_ein) {
    switch (ort_v_ein){
        case 0:
          return "Zuhause"
        case 1:
          return "Zuhause mit Spitex"
        case 2: 
          return "Krankenheim,Pflegeheim"
        case 3:
          return "Altersheim, andere sozialmed. Instit."
        case 4:
          return "Psychiatrische Klinik, anderer Betrieb"
        case 5:
          return "Psychiatrische Abteilung/Klinik, gleicher Betrieb"
        case 6:
          return "anderes Krankenhaus (Akutspital) oder Geburtshaus"
        case 7:
          return "Akutabteilung/-klinik, gleicher Betrieb"
        case 8:
          return "Strafvollzugsanstalt"
        case 9:
          return "andere"
        case 10:
          return "Rehabilitationsklinik,anderer Betrieb"
        case 11:
          return "Rehabilitationsabteilung/-klinik, gleicher Betrieb"
        case 12:
          return "unbekannt"
        default:
          throw new Error("Unbekannt ort_v_ein Wert: " + ort_v_ein)
     }
}