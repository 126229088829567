export default function mapOccupationToValue(original) {
    let ret = "";

    if (original['besch_voll']) {
        ret = concatStringIfEmpty(ret, "voll erwerbstätig");
      }
      if (original['besch_teil']) {
        ret = concatStringIfEmpty(ret, "teilzeit erwerbstätig")
      }
      if (original['besch_haus']) {
        ret = concatStringIfEmpty(ret, "Hausarbeit in eigenem Haushalt")
      }
      if (original['besch_ausb']) {
        ret = concatStringIfEmpty(ret, "in Ausbildung (Schule, Lehre, Studium)")
      }
      if (original['besch_reha']) {
        ret = concatStringIfEmpty(ret, "Rehabilitationsprogramm")
      }
      if (original['besch_gesch']) {
        ret = concatStringIfEmpty(ret, "Arbeit in geschütztem oder beschützendem Rahmen")
      }
      if (original['besch_iv_ahv']) {
        ret = concatStringIfEmpty(ret, "IV, AHV oder andere Rente")
      }
      if (original['besch_nicht']) {
        ret = concatStringIfEmpty(ret, "nicht erwerbstätig oder arbeitslos")
      }
      if (original['besch_unbek']) {
        ret = concatStringIfEmpty(ret, "unbekannt")
      }

      return ret;
}


function concatStringIfEmpty(ret, string) {
    /*if(a8) {
        string.toLowerCase();
        a8 = a8 + ", " + string;
    }*/
    return (ret && ret.concat(", ")).concat(string);
}


