export default function BildungToString(bildung) {
    switch (bildung){
        case 1:
          return "keine abgeschlossene Schulbildung"
        case 2:
          return "obligatorische Volksschule"
        case 3: 
          return "Berufslehre oder Vollzeitberufsschule"
        case 4:
          return "Maturitätsschule"
        case 5:
          return " Höhere Fach- oder Berufsschule"
        case 6:
          return "Universität, Hochschule"
        case 9:
          return "unbekannt"
        default:
          throw new Error("unbekannt bildung Wert: " + bildung)
     }
}