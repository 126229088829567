// JSON.parse(json)
module.exports = {
	json: {
		"title": "Vorhersage Freiheitsbeschränkender Massnahmen",
		"description": "Frühzeitige Vorhersage Freiheitsbeschränkender Massnahmen mit Machine Learning",
		"logoPosition": "right",
		"logoWidth": 200,
		"logoHeight": 100,
		"showQuestionNumbers": "off",
		"showProgressBar": "top",
		"progressBarType": "buttons",
		"showCompletedPage": "false",
		pages: [
			{
				name: "PatientID", elements: [
					{
						"type": "panel",
						"name": "patient_information",
						//"startWithNewLine": false,
						"elements": [
							{
								"type": "text",
								"name": "pid",
								"title": "Bitte geben Sie die Patienten ID ein",
								"inputType": "number",
								"isRequired": "true",
								"visible" : false
							},
							{
								"type": "text",
								"name": "ein_dat",
								"title": "Eintrittsdatum",
								"inputType": "date"
							}
						],
						"title": "Zeitpunkt Eintritt",
						"showNumber": true,
						"showQuestionNumbers": "off"
					},

					{
						"type": "panel",
						"name": "data_collector_information",
						"startWithNewLine": false,
						"elements": [
							{
								"type": "text",
								"name": "clinician",
								"title": "Name ausfüllende Person"
							},
							{
								"type": "text",
								"name": "department",
								"title": "Abteilung"
							},
						],
						"title": "Ausfüllende Person Abteilung",
						"showNumber": true,
						"showQuestionNumbers": "off"
					}
				],
				navigationTitle: "Patient:in",
				navigationDescription: "Eintrittsdatum und ausfüllende Person",
			},
			{
				title: "HoNOS",
				navigationTitle: "HoNOS",
				navigationDescription: "Symptom-Skala",
				description: "Fremdbeurteilung des Schweregrades einer psychischen Störung und der sozialen Funktionsfähigkeit.",
				elements: [{
					name: "h1",
					description: "",
					title: "Skala 1 - Überaktives, aggressives, Unruhe stiftendes oder agitiertes Verhalten",
					type: "radiogroup",
					choices: [
						{ value: 0, text: "0 - Kein Problem dieser Art während des Bewertungszeitraums." },
						{ value: 1, text: "1 - Reizbarkeit, Streitigkeiten, Ruhelosigkeit usw., ohne Handlungsbedarf" },
						{ value: 2, text: "2 - Beinhaltet aggressive Gesten, Schubsen oder Belästigen von Anderen; Drohungen oder verbale Aggression; leichtere Sachbeschädigung (z. B. zerbrochene Tasse, zerbrochenes Fenster); ausgeprägte Hyperaktivität oder Agitiertheit." },
						{ value: 3, text: "3 - Körperlich aggressiv gegenüber anderen Personen oder Tieren (im Ausmaß Kodierung 4 unterschreitend); bedrohliches Auftreten; schwerere Überaktivität oder Zerstörung von Eigentum." },
						{ value: 4, text: "4 - Mindestens ein schwerer körperlicher Angriff gegen andere Personen oder Tiere; Zerstörung von Eigentum (z. B. Brandstiftung); schwere Einschüchterung oder obszönes Verhalten." },
						//    { value: 9, text: "9 - Unkown" }
					],
					isRequired: false
				},
				{
					name: "h2",
					title: "Skala 2 - Absichtliche Selbstverletzung",
					description: "",
					type: "radiogroup",
					choices: [
						{ value: 0, text: "0 - Kein Problem dieser Art während des Bewertungszeitraums." },
						{ value: 1, text: "1 - Flüchtige Gedanken, alles zu beenden, jedoch geringes Risiko während des Bewertungszeitraums; keine Selbstverletzung." },
						{ value: 2, text: "2 - Mässiges Risiko während des Bewertungszeitraums; schließt ungefährliche Selbstverletzung (z. B. Kratzen der Handgelenke) ein." },
						{ value: 3, text: "3 - Mittleres bis schweres Risiko der absichtlichen Selbstverletzung während des Bewertungszeitraums; schließt vorbereitende Handlungen (z. B. das Sammeln von Tabletten) ein." },
						{ value: 4, text: "4 - Schwerer Suizidversuch und/oder schwere absichtliche Selbstverletzung während des Bewertungszeitraums." },
						//   { value: 9, text: "9 - Unkown" }
					],
					isRequired: false
				},
				{
					name: "h3",
					title: "Skala 3 - Problematischer Alkoholkonsum oder Drogenkonsum",
					description: "",
					type: "radiogroup",
					choices: [
						{ value: 0, text: "0 - Kein Problem dieser Art während des Bewertungszeitraums." },
						{ value: 1, text: "1 - Gelegentlich übermäßiger Konsum, jedoch innerhalb der sozialen Norm." },
						{ value: 2, text: "2 - Verlust der Kontrolle über das Trinken oder den Drogenkonsum, jedoch nicht schwer abhängig." },
						{ value: 3, text: "3 - Ausgeprägtes Verlangen nach oder Abhängigkeit von Alkohol oder Drogen, mit häufigem Verlust der Kontrolle; Risikoverhalten unter dem Einfluss von Alkohol oder Drogen." },
						{ value: 4, text: "4 - Durch das Alkohol-/Drogenproblem unfähig gemacht." },
						//  { value: 9, text: "9 - Unkown" }
					],
					isRequired: false
				},
				{
					name: "h4",
					title: "Skala 4 - Kognitive Probleme",
					description: "",
					type: "radiogroup",
					choices: [
						{ value: 0, text: "0 - Kein Problem dieser Art während des Beurteilungszeitraums." },
						{ value: 1, text: "1 - Klinisch unbedeutende Probleme mit Gedächtnis oder Verständnis (z. B. vergisst gelegentlich Namen)." },
						{ value: 2, text: "2 - Leichte, aber eindeutig vorhandene Probleme (z. B. hat sich an einem bekannten Ort verlaufen oder erkennt eine bekannte Person nicht wieder); manchmal bei einfachen Entscheidungen verwirrt." },
						{ value: 3, text: "3 - Ausgeprägte Desorientierung hinsichtlich Zeit, Ort oder Person; verwirrt durch Alltagsereignisse; Sprache ist manchmal unzusammenhängend; mentale Verlangsamung." },
						{ value: 4, text: "4 - Schwere Desorientierung (z. B. erkennt Verwandte nicht wieder); unfallgefährdet; Sprache unverständlich; Bewusstseinstrübung oder Stupor." },
						//  { value: 9, text: "9 - Unkown" }
					],
					isRequired: false
				},
				{
					name: "h5",
					title: "Skala 5 - Probleme in Zusammenhang mit körperlicher Erkrankung oder Behinderung",
					description: "",
					type: "radiogroup",
					choices: [
						{ value: 0, text: "0 - Keine körperlichen Gesundheitsprobleme während des Bewertungszeitraums." },
						{ value: 1, text: "1 - Klinisch unbedeutende Gesundheitsprobleme während des Zeitraums (z. B. Erkältung, leichter Sturz usw.)" },
						{ value: 2, text: "2 - Körperliches Gesundheitsproblem schränkt die Mobilität und Aktivität leicht ein." },
						{ value: 3, text: "3 - Mittlerer Grad der Aktivitätseinschränkung aufgrund eines körperlichen Gesundheitsproblems." },
						{ value: 4, text: "4 - Schwere oder vollständige Funktionsunfähigkeit aufgrund von körperlichen Gesundheitsproblemen." },
						//   { value: 9, text: "9 - Unkown" }
					],
					isRequired: false
				},
				{
					name: "h6",
					title: "Skala 6 - Probleme in Zusammenhang mit Halluzinationen und Wahnvorstellungen",
					type: "radiogroup",
					description: "",
					choices: [
						{ value: 0, text: "0 - Kein Anzeichen von Halluzinationen oder Wahnvorstellungen während des Bewertungszeitraums." },
						{ value: 1, text: "1 - Etwas merkwürdige oder exzentrische Überzeugungen, die nicht mit den kulturellen Normen übereinstimmen." },
						{ value: 2, text: "2 - Wahnvorstellungen oder Halluzinationen (z. B Stimmen, Visionen) sind vorhanden, jedoch besteht geringes Leiden für den Patienten oder eine geringe Manifestation von bizarrem Verhalten, d.h. klinisch vorhanden, aber leicht." },
						{ value: 3, text: "3 - Ausgeprägte Beschäftigung mit Wahnvorstellungen oder Halluzinationen, die starkes Leiden verursacht und/oder sich in offensichtlich bizarrem Verhalten manifestiert, d.h. eher schweres klinisches Problem." },
						{ value: 4, text: "4 - Psychischer Zustand und Verhalten sind schwer und nachteilig durch Wahnvorstellungen oder Halluzinationen beeinträchtigt, mit schweren Auswirkungen auf den Patienten." },
						//  { value: 9, text: "9 - Unkown" }
					],
					isRequired: false
				},
				{
					name: "h7",
					title: "Skala 7 - Gedrückte Stimmung",
					type: "radiogroup",
					description: "",
					choices: [
						{ value: 0, text: "0 - Kein Problem in Zusammenhang mit gedrückter Stimmung während des Bewertungszeitraums." },
						{ value: 1, text: "1 - Bedrückt; oder geringe Veränderungen in der Stimmung." },
						{ value: 2, text: "2 - Leichte(s), jedoch eindeutig vorhandene(s) Depression und Leiden (z. B. Schuldgefühle; Verlust des Selbstwertgefühls)." },
						{ value: 3, text: "3 - Depression mit unangemessenen Selbstvorwürfen; zwanghaft beschäftigt mit Schuldgefühlen." },
						{ value: 4, text: "4 - Schwere oder sehr schwere Depression mit Schuld oder Selbstanklage." },
						//  { value: 9, text: "9 - Unkown" }
					],
					isRequired: false
				},
				{
					name: "h8",
					title: "Skala 8 - Andere psychische und verhaltensbezogene Probleme ",
					type: "radiogroup",
					description: "",
					choices: [
						{ value: 0, text: "0 - Kein Anzeichen für irgendeines dieser Probleme während des Bewertungszeitraums." },
						{ value: 1, text: "1 - Nur klinisch unbedeutende Probleme." },
						{ value: 2, text: "2 - Ein Problem ist klinisch in leichter Ausprägung vorhanden (z. B. Patient hat ein gewisses Ausmaß an Kontrolle)." },
						{ value: 3, text: "3 - Gelegentlich schwerer Anfall oder Leiden, mit Verlust der Kontrolle (z. B. Patient muss sämtliche Angst hervorrufenden Situationen vermeiden, einen Nachbarn als Hilfe hinzuziehen usw.), d.h. eher schwere Ausprägung des Problems." },
						{ value: 4, text: "4 - Schweres Problem beherrscht die meisten Aktivitäten." },
						//  { value: 9, text: "9 - Unkown" }
					],
					isRequired: false
				},
				{
					name: "h9",
					title: "Skala 9 - Probleme mit Beziehungen",
					type: "radiogroup",
					description: "",
					choices: [
						{ value: 0, text: "0 - Kein bedeutendes Problem während des Bewertungszeitraums." },
						{ value: 1, text: "1 - Geringe nicht-klinische Probleme." },
						{ value: 2, text: "2 - Deutliches Problem beim Aufbau oder Aufrechterhalten von unterstützenden Beziehungen: Patient beklagt sich und/oder Probleme sind für andere offensichtlich." },
						{ value: 3, text: "3 - Persistierendes größeres Problem aufgrund von aktivem oder passivem Rückzug aus sozialen Beziehungen und/oder Beziehungen, die geringen oder gar keinen Trost oder Unterstützung bieten." },
						{ value: 4, text: "4 - Schwere und leidvolle soziale Isolation aufgrund der Unfähigkeit, sozial zu kommunizieren und/oder Rückzug aus sozialen Beziehungen." },
						//  { value: 9, text: "9 - Unkown" }
					],
					isRequired: false
				},
				{
					name: "h10",
					title: "Skala 10 - Probleme mit alltäglichen Aktivitäten",
					type: "radiogroup",
					description: "",
					choices: [
						{ value: 0, text: "0 - Kein Problem während des Bewertungszeitraums; gute Funktionsfähigkeit in allen Bereichen." },
						{ value: 1, text: "1 - Nur klinisch unbedeutende Probleme (z. B. unordentlich, unorganisiert)." },
						{ value: 2, text: "2 - Angemessene Selbstpflege, jedoch erheblicher Leistungsmangel bei einer oder mehreren komplexen Fertigkeiten." },
						{ value: 3, text: "3 - Erhebliches Problem in einem oder mehreren Bereichen der Selbstpflege (Essen, Waschen, Ankleidung, Morgentoilette) sowie starke Unfähigkeit, mehrere komplexe Fertigkeiten auszuüben." },
						{ value: 4, text: "4 - Schwere Behinderung oder vollständige Unfähigkeit in allen oder nahezu allen Bereichen der Selbstpflege und komplexen Fertigkeiten." },
						//  { value: 9, text: "9 - Unkown" }
						// //"logo": "https://i.postimg.cc/7ZTbvV7R/logo.png",  // to fix
					],
					isRequired: false
				},
				{
					name: "h11",
					title: "Skala 11 - Probleme durch die Wohnbedingungen",
					type: "radiogroup",
					description: "",
					choices: [
						{ value: 0, text: "0 - Unterkunft und Wohnbedingungen sind annehmbar; hilfreich, um jegliche Behinderung, welche auf der Skala 10 eingeschätzt wird, auf dem geringst möglichen Niveau zu halten; und die Selbsthilfe unterstützend." },
						{ value: 1, text: "1 - Unterkunft ist einigermaßen akzeptabel, obgleich geringfügige oder vorübergehende Probleme bestehen (z. B. kein optimaler Ort, nicht die bevorzugte Wahl, usw.)" },
						{ value: 2, text: "2 - Bedeutendes Problem mit einem oder mehreren Aspekten der Unterkunft und/oder der Verwaltung (z. B. beschränkte Entscheidungsfreiheit; Personal oder Personen des Haushalts wissen nicht, wie die Behinderung einzugrenzen ist oder wie der Einsatz oder die Entwicklung neuer oder intakter Fertigkeiten unterstützt werden kann). " },
						{ value: 3, text: "3 - Zahlreiche belastende Probleme mit der Unterkunft (z. B. einige elementare Notwendigkeiten sind nicht vorhanden); Die Ausstattung der Unterkunft bietet minimale oder gar keine Möglichkeiten, die Unabhängigkeit des Patienten zu verbessern." },
						{ value: 4, text: "4 - Die Unterkunft ist inakzeptabel, wodurch die Probleme des Patienten verschlimmert werden (z.B. elementare Notwendigkeiten sind nicht vorhanden, dem Patienten droht Räumung oder „Obdachlosigkeit“ oder die Wohnbedingungen sind anderweitig nicht tragbar)." },
						//  { value: 9, text: "9 - Unkown" }
					],
					isRequired: false
				},
				{
					name: "h12",
					title: "Skala 12 - Probleme durch die Bedingungen im Beruf und im Alltag",
					type: "radiogroup",
					description: "",
					choices: [
						{ value: 0, text: "0 - Milieu des Patienten ist akzeptabel: hilfreich, um jegliche Behinderung, welche auf der Skala 10 eingeschätzt wird, auf dem geringst möglichen Niveau zu halten; und die Selbsthilfe unterstützend." },
						{ value: 1, text: "1 - Geringfügige oder vorübergehende Probleme (z. B. verspäteter Erhalt von Überweisungen): angemessene Einrichtungen sind verfügbar, jedoch nicht immer zu den gewünschten Zeiten, usw." },
						{ value: 2, text: "2 - Beschränkte Auswahl an Aktivitäten; Mangel an angemessener Toleranz (z. B. zu Unrecht verweigerter Zutritt zu öffentlichen Bibliotheken oder Bädern usw.); benachteiligt durch Fehlen einer permanenten Adresse; unzureichende Betreuung oder professionelle Unterstützung; hilfreiches Milieu verfügbar, jedoch nur für eine sehr begrenzte Stundenzahl." },
						{ value: 3, text: "3 - Ausgeprägter Mangel an verfügbaren qualifizierten Dienstleistungen, die helfen, das Ausmaß der bestehenden Behinderung herabsetzen; keine Möglichkeiten, die intakten Fertigkeiten einzusetzen oder neue hinzuzufügen; unqualifizierte Betreuung, welche schwer zugänglich ist." },
						{ value: 4, text: "4 - Mangel an irgendwelchen Gelegenheiten für Tagesaktivitäten verschlimmert das Problem." },
						// { value: 9, text: "9 - Unkown" }
					],
					isRequired: false
				}
				]
			},
			{
				name: "Social data",
				title: "Sozio-ökonomische Daten",
				navigationTitle: "Sozio-ökonomische Daten",
				navigationDescription: "Geschlecht, Alter und Hintergrund",
				elements: [
					{
						"type": "panel",
						"name": "basic",
						"elements": [{
							"type": "boolean",
							"name": "geschl",
							"title": "Geschlecht:",
							"isRequired": true,
							"labelTrue": "Weiblich",
							"labelFalse": "Männlich",
						},
						{
							"type": "text",
							"name": "alter",
							"title": "Alter:",
							"startWithNewLine": false,
							"isRequired": true,
							"inputType": "number"
						}]
					},
					{
						"type": "radiogroup",
						"name": "bildung",
						"title": "Bildung:",
						"colCount": 2,
						"isRequired": false,
						"choices": [
							{ value: 1, text: "keine abgeschlossene Schulbildung" },
							{ value: 2, text: "obligatorische Volksschule" },
							{ value: 3, text: "Berufslehre oder Vollzeitberufsschule" },
							{ value: 4, text: "Maturitätsschule" },
							{ value: 5, text: "Höhere Fach- oder Berufsschule" },
							{ value: 6, text: "Universität, Hochschule" },
							// { value: 9, text: "Unknown" },
						],
					},
					{
						"type": "radiogroup",
						"name": "zivilstand",
						"title": "Zivilstand vor Eintritt:",
						"colCount": 2,
						//"startWithNewLine": false,
						"isRequired": false,
						"choices": [
							{ value: 1, text: "ledig" },
							{ value: 2, text: "verheiratet, zusammenlebend" },
							{ value: 3, text: "verheiratet, getrennt lebend" },
							{ value: 4, text: "verwitwet" },
							{ value: 5, text: "geschieden" },
							// { value: 9, text: "Unknown" },
						],
					},
					{
						"type": "checkbox",
						"name": "besch",
						"title": "Beschäftigung vor Eintritt (Mehrfachantwort möglich):",
						"colCount": 2,
						"isRequired": true,
						"choices": [
							{ value: 0, text: "voll erwerbstätig" },
							{ value: 1, text: "teilzeit erwerbstätig" },
							{ value: 2, text: "Hausarbeit in eigenem Haushalt" },
							{ value: 3, text: "in Ausbildung (Schule, Lehre, Studium)" },
							{ value: 4, text: "Rehabilitationsprogramm" },
							{ value: 5, text: "Arbeit in geschütztem oder beschützendem Rahmen" },
							{ value: 6, text: "IV, AHV oder andere Rente" },
							{ value: 7, text: "nicht erwerbstätig oder arbeitslos" },
							// { value: 9, text: "Unknown"}
						],
					},
				],
			},
			{
				name: "Admission data",
				title: "Angaben zum Eintritt",
				navigationTitle: "Angaben zum Eintritt",
				navigationDescription: "Eintritt und frühere Aufenthalte",
				elements: [
					{
						"type": "boolean",
						"colCount": 0,
						"name": "ffe",
						"title": "Handelt es sich um eine Fürsorgerische Unterbringung",
						"labelTrue": "Yes",
						"labelFalse": "No",
						"hideNumber": true,
						"isRequired": true,
					},
					{
						"type": "radiogroup",
						"name": "ort_v_ein",
						"title": "Aufenthaltsort vor dem Eintritt:",
						"colCount": 2,
						"isRequired": false,
						"choices": [
							{ value: 0, text: "Zuhause" },
							{ value: 1, text: "Zuhause mit Spitex" },
							{ value: 2, text: "Krankenheim, Pflegeheim" },
							{ value: 3, text: "Altersheim, andere sozialmed. Instit." },
							{ value: 4, text: "Psychiatrische Klinik, anderer Betrieb" },
							{ value: 5, text: "Psychiatrische Abteilung/Klinik, gleicher Betrieb" },
							{ value: 6, text: "anderes Krankenhaus (Akutspital) oder Geburtshaus" },
							{ value: 7, text: "Akutabteilung/-klinik, gleicher Betrieb" },
							{ value: 8, text: "Strafvollzugsanstalt" },
							{ value: 9, text: "andere" },
							{ value: 10, text: "Rehabilitationsklinik, anderer Betrieb" },
							{ value: 11, text: "Rehabilitationsabteilung/-klinik, gleicher Betrieb" },
							// { value:12, text: "Unknown"},
						],
					},
					{
						"type": "radiogroup",
						"name": "ein_art",
						"title": "Eintrittsart:",
						"colCount": 2,
						"isRequired": false,
						"choices": [
							{ value: 1, text: "Notfall (Behandlung innerhalb von 12 Std. unabdingbar)" },
							{ value: 2, text: "angemeldet, geplant" },
							{ value: 3, text: "Geburt (Kind in dieser Klinik geboren)" },
							{ value: 4, text: "interner Übertritt" },
							{ value: 5, text: "Verlegung innerhalb 24 Std." },
							{ value: 8, text: "andere" },
							//  { value:9, text: "Unknown"},
						],
					},
					{
						"type": "radiogroup",
						"name": "einw_inst",
						"title": "Einweisende Instanz:",
						"colCount": 2,
						"isRequired": false,
						"choices": [
							{ value: 1, text: "selbst, Angehörige" },
							{ value: 2, text: "Rettungsdienst (Ambulanz, Polizei)" },
							{ value: 3, text: "Arzt" },
							{ value: 4, text: "nichtmedizinischer Therapeut" },
							{ value: 5, text: "sozialmedizinischer Dienst" },
							{ value: 6, text: "Justizbehörden" },
							{ value: 8, text: "andere" },
							//   { value:9, text: "Unknown"},
						],
					},
					{
						"type": "boolean",
						"name": "prevFM",
						"title": "Wurde die Patient:in innerhalb der letzten 6 Monate bereits in dieser Klinik behandelt?",
						"isRequired": false,
						"labelTrue": "Ja",
						"labelFalse": "Nein",
						"hideNumber": true,
						"colCount": 1,
					},
					{
						"type": "rating",
						"name": "fm_sum",
						"title": "Bei wie vielen vorgängigen Aufenthalten der Patient:in in dieser Klinik kam es bereits zu Freiheitsbeschränkenden Massnahme?",
						"minRateDescription": "Keine/Unbekannt",
						"maxRateDescription": "Bei drei oder mehr Aufenthalten",
						"visibleIf": "{prevFM}='true'",
						"isRequired": false,
						"rateValues": [
							{ value: 0, text: "0" },
							{ value: 1, text: "1" },
							{ value: 2, text: "2" },
							{ value: 3, text: "3" },
						],
					},
				]
			},
			{
				name: "Medical and dropout data",
				title: "Diagnose und Dropout Information",
				navigationTitle: "Diagnose und Dropout Information",
				navigationDescription: "Diagnose und Gründe für Dropouts",
				elements: [
					{
						"type": "radiogroup",
						"name": "hpt_diagn_num",
						"title": "Hauptdiagnose:",
						"colCount": 1,
						"isRequired": true,
						"choices": [
							{ value: 0, text: "F0: Organische, einschliesslich symptomatischer psychischer Störungen" },
							{ value: 1, text: "F1: Psychische und Verhaltensstörungen durch psychotrope Substanzen"},
							{ value: 2, text: "F2: Schizophrenie, schizotype und wahnhafte Störungen" },
							{ value: 3, text: "F3: Affektive Störungen" },
							{ value: 4, text: "F4: Neurotische, Belastungs- und somatoforme Störungen" },
							{ value: 5, text: "F5: Verhaltensauffälligkeiten mit körperlichen Störungen und Faktoren" },
							{ value: 6, text: "F6: Persönlichkeits- und Verhaltensstörungen" },
							{ value: 7, text: "F7: Intelligenzstörung" },
							{ value: 8, text: "F8: Entwicklungsstörungen" },
							{ value: 9, text: "F9: Verhaltens- und emotionale Störungen mit Beginn in der Kindheit und Jugend" },
							{ value: 10, text: "F99: Nicht näher bezeichnete psychische Störungen" },
							{ value: 11, text: "Andere medizinische Hauptdiagnose" },
						],

					},
					{
						"type": "radiogroup",
						"name": "dropout_pb_ein",
						"title": "Dropout BSCL:",
						"colCount": 2,
						"isRequired": false,
						"choices": [
							{ value: 0, text: "Kein Dropout (BSCL ausgefüllt oder Bereitschaft vorhanden)" },
							{ value: 1, text: "Ablehnung Patient" },
							{ value: 2, text: "Sprache" },
							{ value: 3, text: "zu krank" },
							{ value: 4, text: "Todesfall" },
							{ value: 7, text: "unvorhergesehener Austritt oder Nicht-Rückkehr aus Urlaub" },
							//  { value:9, text: "Unknown"},
						],
					},
					/*{
							"type": "radiogroup",
							"name": "dropout_ph_ein",
							"title": "Dropout HoNOS:",
							"startWithNewLine": false,
							"colCount": 1,
							"isRequired": false,
							"choices": [
									{ value:0, text: "No dropout"},
									{ value:1, text: "Discharged within 7 days"},
									{ value:2, text: "Other"},
							//  { value:9, text: "Unknown"},
							],
					}, */
				]
			},
			{

				name: "Clinic data",
				title: "Kommentare",
				navigationTitle: "Kommentare",
				navigationDescription: "Kommentare zum aktuellen Testfall",
				elements: [
					{
						//   "type": "panel",
						//   "name": "clinic_profile",
						//   "elements": [ {

						"type": "rating",
						"name": "home_scale",
						"minRateDescription": "",
						"maxRateDescription": "",
						"title": "Prozentualer Anteil an Patient:innen, die vor dem Klinikeintritt zuhause waren",
						"colCount": 0,
						"isRequired": true,
						"value": 1,
						"visible" : false,
						"rateValues": [
							{ value: 2, text: "Weniger als 60%" },
							{ value: 1, text: "60% bis 80%" },
							{ value: 0, text: "Mehr als 80%" },
						],
					},
					{
						"type": "rating",
						"name": "urgent",
						"title": "Prozentualer Anteil an Notfall-Eintritten",
						"colCount": 0,
						"minRateDescription": "",
						"maxRateDescription": "",
						"startWithNewLine": false,
						"isRequired": true,
						"value": 1,
						"visible" : false,
						"rateValues": [
							{ value: 0, text: "Weniger als 25%" },
							{ value: 1, text: "25% bis 50%" },
							{ value: 2, text: "Mehr als 50%" },
						],
						//  },

						//]
					},
					{
						"type": "boolean",
						"name": "F2_scale",
						"title": "Ist der Anteil an F2 Hauptdiagnosen in der Klinik grösser als 20%?",
						"isRequired": true,
						"labelTrue": "Ja",
						"labelFalse": "Nein",
						"value": false,
						"hideNumber": true,
						"colCount": 1,
						"visible" : false,
					},
					{
						type: "comment",
						name: "comment",
						title: "Kommentare zum aktuellen Fall"
					}
				]
			}
		]
	}
}
