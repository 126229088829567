// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCn3iNbFRWWUxR6iu2IuQKZyItdqVUn53s",
  authDomain: "fm-predict.firebaseapp.com",
  projectId: "fm-predict",
  storageBucket: "fm-predict.appspot.com",
  messagingSenderId: "243558430797",
  appId: "1:243558430797:web:2072164fa254d3284f0404"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app)
export default app