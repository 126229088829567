export default function home_scale_ToString(home_scale) {
    switch (home_scale){
        case 0:
          return "Mehr als 80%"
        case 1:
          return "Zwischen 60% and 80%"
        case 2: 
          return "Weniger als 60%"
        default:
          throw new Error("Unbekannt home_scale Wert: " + home_scale)
     }
}