export default function urgent_scale_ToString(urgent) {
    switch (urgent){
        case 0:
          return "Weniger als 25%"
        case 1:
          return "Zwischen 25% und 50%"
        case 2: 
          return "Mehr als 50%"
        default:
          throw new Error("Unbekannt urgent Wert: " + urgent)
     }
}