export default function dropout_pb_toString(dropout_pb_ein) {
    switch (dropout_pb_ein){
        case 0:
          return "Kein Dropout"
        case 1:
          return "Ablehnung Patient"
        case 2: 
          return "Sprache"
        case 3:
          return "Zu krank"
        case 4:
          return "Todesfall"
        case 6:
          return "Austritt innerhalb 24 h nach Eintritts-BSCL-Messung"
        case 7:
          return "unvorhergesehener Austritt oder NichtRückkehr aus Urlaub"
        case 9:
          return "Unbekannt"
        default:
          throw new Error("Unbekannt dropout_pb_ein Wert: " + dropout_pb_ein)
     }
}