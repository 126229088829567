import '../assets/main.css';
import '../assets/surveyDef.js'
import { useNavigate } from 'react-router-dom'
import dist_first from '../assets/imgs/dist_first.png'
import db from '../assets/imgs/db.png'
import gb from '../assets/imgs/gb.png'
import vis from '../assets/imgs/vis.png'
import auprc from '../assets/imgs/auprc.png'
import first_fm from '../assets/imgs/first_fm.png'

function Home() {
    const navigate = useNavigate()
    return (
        <div>
        <div className='container-lg  pt-5 pb-5 pt-lg-6'>
            <h1 className="ls-tight font-bolder display-6 mb-5">Einführung</h1>
            <hr />
                <div className="ms-4 py-2 d-flex">
                    <div className="">
                        <p className="text-sm">
                            <b>Dieser Prototyp wird der Clienia Schlössli zu Demonstrationszwecken zur Verfügung gestellt. Die Applikation darf nicht zur
                            Behandlung von realen Personen eingesetzt werden. Die Eingabe der Patienten- und Fallidentifikationsnummern ist aus diesem Grund in der aktuellen Version
                            nicht möglich. Testfälle werden fortlaufend nummeriert.</b>
                        </p>
                    </div>
                </div>
            <br></br>
            <br></br>
            <div className="bg-white position-relative d-flex p-2 rounded shadow-sm mb-4" role="alert">
                <div className="w-1 bg-success rounded-pill" />
                <div className="ms-4 py-2 d-flex">
                    <div className="">
                        <span className="d-block font-semibold text-heading">Hintergrund</span>
                        <p className="text-sm">
                            Das vorliegende Projekt basiert auf einem vorverarbeiteten Datensatz des ANQ. Ein Algorithmus wurde an rund 150'000 klinischen Fällen 
                            aus der ganzen Schweiz trainiert und soll in Zukunfft dafür eingesetzt werden können, Freiheitsbschränkende Massnahmen zu reduzieren. 
                            Basierend auf medizinischen (z.B. HoNOS, Diagnose), sozio-ökonomischen und Einweisungsdaten (z.B. Fürsorgerische Unterbringung, Dropout), 
                            die bei Eintritt erhoben werden, wird das Gesamtrisiko von Freiheitsbeschränkenden Massnahmen vorhergesagt. 
                            Zusätzlich zum vorhergesagten Gesamtrisiko, wird die Wahrscheinlichkeit, ob es sich um eine Zwangsmedikation handeln wird, und
                            ob die Massnahme innerhalb der ersten 24 Stunden des Aufenthalts zu Anwendung kommen wird, ausgegeben.  
                        </p>
                    </div>
                </div>
            </div>
            <br></br>
            <br></br>
            <div className="bg-white position-relative d-flex p-2 rounded shadow-sm mb-4" role="alert">
                <div className="w-1 bg-success rounded-pill" />
                <div className="ms-4 py-2 d-flex">
                    <div className="">
                        <span className="d-block font-semibold text-heading">Ablauf</span>
                        <p className="text-sm">
                        Die Vorhersagen basieren auf Angaben, welche die behandelnde Person über Patient:innen macht.
                        Es ist wichtig, dass vorgängig eine erste Überprüfung des Zustands der Patient:in stattgefunden hat.
                        Da sich zeigt, dass fehlende Daten häufig einen erheblichen Einfluss auf das Risiko einer FM haben, sollten sie demnach auch als solche angegeben werden 
                        (d. h. die Frage sollte leer bleiben). Idealerweise sollte der Test so zeitnah wie möglich am Tag der Aufnahme durchgeführt werden.
                        </p>
                    </div>
                </div>
            </div>

            <div class="flex justify-between" >
                <div class="relative w-[300px]">
                    <img src={gb} />
                    <div style={{ cursor: "pointer" }} onClick={() => { navigate('/Prediction') }} class="transform opacity-70 bg-green-100  absolute bottom-0 left-0 right-0 px-4 py-0">
                        <h3 class="hover:opacity-100 text-xl text-black font-bold">
                            Eingabemaske</h3>
                        <p class="mt-2 text-sm text-black">Machine Learning Vorhersage</p>
                    </div>
                </div>
                <div class="relative w-[300px]">
                    <img src={vis} />
                    <div style={{ cursor: "pointer" }} onClick={() => { navigate('/Analytics') }} class="transform opacity-70 bg-green-100  absolute bottom-0 left-0 right-0 px-4 py-0">
                        <h3 class="hover:opacity-100 text-xl text-black font-bold">
                            Analysen</h3>
                        <p class="mt-2 text-sm text-black">Visualisierung Resultate</p>
                    </div>
                </div>
                <div class="relative w-[210px]">
                    <img src={db} />
                    <div style={{ cursor: "pointer" }} onClick={() => { navigate('/Database') }} class="transform opacity-70 bg-green-100  absolute bottom-0 left-0 right-0 px-4 py-0">
                        <h3 class="hover:opacity-100 text-xl text-black font-bold">
                            Datenbank</h3>
                        <p class="mt-2 text-sm text-black">Durchgeführte Tests</p>
                    </div>
                </div>
            </div>


        </div>


        </div>

    )
}

export default Home
